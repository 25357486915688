let appMode = process.env.REACT_APP_ENV;
let ASSET_URL = "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/tawasi-s3/";
let URL;
console.log("appMode", appMode);

if (appMode === "development") {
  // URL = "http://localhost:5500/api/";
  // URL = "http://54.153.176.44:5500/api/";
  URL ="https://dev.backend.tawasi.online/api/"
} else {
  URL = "https://backend.tawasi.online/api/";
  // URL = "http://localhost:5500/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",
  // Auth API
  logout: "collector/auth/logout",
  login: "collector/auth/login",

  profile: "collector/auth/get-profile",
  updateProfile: "collector/auth/update-profile",
  changePassword: "collector/auth/change-password",
  updateAppSetting: "collector/auth/update-app-setting",
  forgotPassword: "collector/auth/forgot-password",
  verifyOTP: "collector/auth/verify-otp",
  resetPassword: "collector/auth/reset-password",
  collection: "collector/collection",
  notification: "collector/notification",

  common: { 
    restaurantCategories: "categories",
    foodCategories: "food-categories",
    countries: "",

    foodItems: "common/food-items",
    countries: "common/",
    cities: "",
  },
};

export default apiPath;
