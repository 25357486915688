import { UndoOutlined } from "@ant-design/icons";
import { Button, Card, Col, Image, Row, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";
import { dateString } from "../../helper/functions";
import Currency from "../../components/Currency";
import { Link } from "react-router-dom";

function PendingFive() {
  const { setPageHeading } = useContext(AppStateContext);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { request } = useRequest();
  const api = {
    status: apiPath.statusQuote,
    list: apiPath.collection + `/payment/pending`,
  };

  const fetchData = () => {
    setLoading(true);
    request({
      url: `${api.list}?page=1&pageSize=5&status=Pending`,
      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const columns = [
    {
      title: lang("Rest. ID"),
      dataIndex: "id",
      render: (_, { restaurant_id }) =>
        `#${restaurant_id ? restaurant_id.uid : ""}`,
    },
    {
      title: lang("NAME"),
      dataIndex: "name",
      render: (_, { restaurant_id }) => `${restaurant_id.name}`,
    },
    {
      title: lang("payment Date & time"),
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },
    {
      title: lang("Country"),
      dataIndex: "country",
      key: "country",
      render: (_, { country_id }) => `${country_id.name}`,
    },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      render: (_, { city_id }) => `${city_id.name}`,
    },
    {
      title: lang("Area"),
      dataIndex: "payment_Method",
      key: "payment_Method",
      render: (_, { restaurant_id }) =>
        `${restaurant_id.area ? restaurant_id.area.name : ""}`,
    },
    {
      title: lang("Total Amount"),
      dataIndex: "delivery_status",
      key: "delivery_status",
      render: (_, { amount }) => <Currency price={amount ? amount : 0} />,
    },
    {
      title: lang("Payment Mode"),
      dataIndex: "Item_Description",
      key: "Item_Description",
      render: (_, { payment_mod }) => (payment_mod ? payment_mod : 0),
    },
    // {
    //     title: "Pending Amount",
    //     dataIndex: "pendingAmount",
    //     key: "pendingAmount",
    // },
    {
      title: lang("Payment receipt"),
      dataIndex: "Item_Description",
      key: "Item_Description",
      render: (_, { image, name }) => (
        <Image src={image ? image : null} className="table-img image-doc" />
      ),
    },
    {
      title: lang("status"),
      render: (_, { status }) => {
        return (
          <>
            <Tooltip
              title={lang("Approval Pending")}
              color={"purple"}
              key={"viewDetail"}
            >
              <Button
                className="btnStyle btnOutlineDelete"
                // onClick={(e) => view(record._id)}
              >
                {lang(`${status}`)}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(lang("Collection Management"));
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <>
                <div className="tab_inner_tit">
                  <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
                    <h3>{lang("Pending Approval")}</h3>
                    <div className="d-flex align-items-center gap-3">
                      <Link
                        to="/pending-approvals?status=Pending"
                        className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                      >
                        {lang("VIEW ALL")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive customPagination withOutSearilNo">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PendingFive;
