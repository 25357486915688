import React, { useState, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";

const Currency = ({ price }) => {
   const {currency} = useAuthContext()
    return (
        <>{currency}{" "}{(parseFloat(price)).toLocaleString("en-US",{minimumFractionDigits:0})}</> 
        
    )
}

export const currency = (price) => {
    const getCurrency = JSON.parse(localStorage.getItem("currencySet")) ? JSON.parse(localStorage.getItem("currencySet")) : { value: '1', text: 'AED', label: 'AED' };
    return (`${getCurrency.text}  ${(price * parseFloat(getCurrency.value)).toFixed(2)}`)
}

export const CurrencyWithOutLabel = (price) => {
    const getCurrency = JSON.parse(localStorage.getItem("currencySet")) ? JSON.parse(localStorage.getItem("currencySet")) : { value: '1', text: 'AED', label: 'AED' };
    return (`${(price * parseFloat(getCurrency.value)).toFixed(2)}`)
}

export default (Currency);