import React, { useEffect, useState, useRef } from "react";
import { Avatar, List, Skeleton, Pagination } from "antd";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import apiPath from "../constants/apiPath";
import lang from "../helper/langHelper";
import { dateString } from "../helper/functions";
import { useAppContext } from "../context/AppContext";
import useRequest from "../hooks/useRequest";

export const NotificationList = () => {
  const { language } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
  });

  const [hasMore, setHasMore] = useState(true);

  const containerRef = useRef();

  const { request } = useRequest();

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD",
    ),
  };

  const fetchData = (pagination) => {
    request({
      url:
        apiPath.notification +
        `?status=${""}&page=${pagination ? pagination.page : 1}&pageSize=${pagination && pagination?.pageSize ? pagination?.pageSize : 10
        }&search=${""}`,
      method: "GET",
      onSuccess: ({ data, page, pageSize, total, status }) => {
        if (status) {
          setList(data);
          setLoading(false);
          setPagination({
            current: page,
            pageSize,
            total
          })

        }
      },
      onError: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  };

  const onChange = (page, pageSize) => {
    fetchData({ page, pageSize })
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    console.log(list.length, "---");
  }, []);

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <>

          {list.map((data, index) => (
            <div className="notification-card" key={index}>
              <h4>
                {moment(data._id).format("YYYY-MM-DD") === dates.today
                  ? lang("Today's Notifications")
                  : moment(data._id).format("YYYY-MM-DD") === dates.yesterday
                    ? lang("Yesterday's Notifications")
                    : moment(data._id).format("YYYY-MM-DD")}
              </h4>
              <List
                key={"groupItem" + index}
                itemLayout="horizontal"
                dataSource={data.notifications}
                renderItem={(item, index) => (

                  <List.Item key={index}>
                    <List.Item.Meta
                      avatar={<Avatar src={item?.users[0]?.image} />}
                      description={
                        language === "en" ? item.users[0].message : item.users[0].ar_message
                      }

                    />
                    <div>{dateString(item.created_at, "hh:mm a")}</div>
                  </List.Item>
                )}
              />
            </div>
          ))}
          {list.length ? <Pagination {...pagination} align="end" onChange={onChange} showSizeChanger /> : lang("No Notificatons")}
        </>
      )}
    </div>
  );
};
