export const Last20Years = Array.from({ length: 20 }, (_, index) => (new Date().getFullYear() - index).toString());
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
]; 

export const rolesOptions = [
    { name: "category-management", label: "Category Management" },
    { name: "restaurant-manager", label: "Restaurant Management" },
    { name: "discount-manager", label: "Discount Management" },
    { name: "order-manager", label: "Order Management" },
    { name: "driver-manager", label: "Driver Management" },
    { name: "promotion-manager", label: "Promotion Management" },
    { name: "banner-manager", label: "Banner Management" },
    { name: "collection-manager", label: "Collection Management" },
    { name: "cms-manager", label: "CMS Management" },
    { name: "finance", label: "Finance" },
  ];